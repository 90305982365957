














































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { deleteBlogPost, getBlogPost, saveBlogPost, saveBlogPostWithMedia } from '@/api/blogPosts'
import Sticky from '@/components/Sticky/index.vue'
import { Form } from 'element-ui'
import {
  locales,
  convertToMapTranslations,
  convertToArrayTranslations,
  validateForm,
  errorMsg,
  successMsg, confirmDialog
} from '@/utils'
import { BlogPostTranslation } from '@/models/BlogPostTranslation'
import Tinymce from '@/components/Tinymce/index.vue'
import { AppModule, DeviceType } from '@/store/modules/app'
import { BlogPost } from '@/models/BlogPost'
import { UserModule } from '@/store/modules/user'

@Component({
  name: 'BlogPostDetail',
  components: {
    Tinymce,
    Sticky
  }
})

export default class extends Vue {
  @Prop({ default: false }) private isEdit!: boolean
  private blogPost = new BlogPost({
    office_id: UserModule.officeId
  });

  private translations = convertToMapTranslations([], BlogPostTranslation)
  private skeletonLoader = false
  private saveLoader = false
  private imageUrl = ''
  private imageUploaded: any = undefined
  private states = ['draft', 'published']
  private languages = locales
  private language = locales[0]

  get files() {
    return [this.imageUrl]
  }

  private handleImageSuccess(res: any, file: any) {
    this.imageUploaded = file
    this.imageUrl = URL.createObjectURL(file.raw)
  }

  private switchUrl():string {
    if (this.imageUrl.length > 1) {
      return this.imageUrl
    }
    if (this.blogPost.image != null) {
      return '/static/blog/' + this.blogPost.id + '/images/' + this.blogPost.image
    }
    return '/static/public/placeholder.png'
  }

  private beforeImageUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      this.$message.error('Image picture size can not exceed 2MB!')
    }
    return isLt2M
  }

  created() {
    if (this.isEdit && this.$route.params && this.$route.params.id) {
      return this.getItem(this.$route.params.id)
    } else {
      this.imageUrl = '/static/public/placeholder.png'
    }
  }

  get pageTitle() {
    return this.isEdit
      ? this.$t('blogPostDetail.editBlogPost')
      : this.$t('blogPostDetail.newBlogPost')
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async getItem(id: string) {
    this.skeletonLoader = true
    try {
      const { data } = await getBlogPost({
        id: id
      })
      this.blogPost = data
      if (this.blogPost.image != null) {
        this.imageUrl = '/static/blog/' + this.blogPost.id + '/images/' + this.blogPost.image
      } else {
        this.imageUrl = '/static/public/placeholder.png'
      }

      this.translations = convertToMapTranslations(data.translations, BlogPostTranslation)
    } catch (err) {}
    this.skeletonLoader = false
  }

  private async deleteItem() {
    const [data] = await confirmDialog('blogPostList.delete')
    if (!data) {
      return
    }
    try {
      await deleteBlogPost({
        id: this.blogPost.id
      })
      await successMsg('blog_posts.deleteSuccess')
      return this.$router.push('/blog/list')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async submitForm() {
    const [valid] = await validateForm(this.$refs.form as Form)
    if (!valid) {
      return errorMsg('form.formErrors')
    }
    this.saveLoader = true
    this.blogPost.translations = convertToArrayTranslations(this.translations, BlogPostTranslation)
    try {
      let data: any
      if (this.imageUploaded) {
        const formData = new FormData()
        formData.append('blogPost', JSON.stringify(this.blogPost))
        formData.append('image', this.imageUploaded.raw)
        data = (await saveBlogPostWithMedia(formData)).data
      } else {
        data = (await saveBlogPost(this.blogPost)).data
      }
      if (!this.isEdit) {
        return this.$router.push('/blog/edit/' + data.id)
      }
      await successMsg('blogPostDetail.successSave')
    } catch (err) {
      await errorMsg('api.serverError')
    }
    this.saveLoader = false
  }
}
