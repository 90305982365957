import request from '@/utils/request'

export const getBlogPosts = (data: any) =>
  request({
    method: 'post',
    url: '/api/blog_posts/getBlogPosts',
    data
  })

export const getBlogPostsCount = (data: any) =>
  request({
    method: 'post',
    url: '/api/blog_posts/getBlogPostsCount',
    data
  })

export const getBlogPost = (params: any) =>
  request({
    method: 'get',
    url: '/api/blog_posts/getBlogPost',
    params
  })

export const saveBlogPost = (data: any) =>
  request({
    method: 'post',
    url: '/api/blog_posts/saveBlogPost',
    data
  })

export const saveBlogPostWithMedia = (formData: any) =>
  request.post('/api/blog_posts/saveBlogPostWithMedia', formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

export const saveBlogPostBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/blog_posts/saveBlogPostBulk',
    data
  })

export const deleteBlogPost = (params: any) =>
  request({
    method: 'get',
    url: '/api/blog_posts/deleteBlogPost',
    params
  })

export const deleteBlogPostBulk = (data: any) =>
  request({
    method: 'post',
    url: '/api/blog_posts/deleteBlogPostBulk',
    data
  })
