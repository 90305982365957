import { BlogPostTranslation } from '@/models/BlogPostTranslation'
/* eslint-disable camelcase */
export class BlogPost {
  id: string;
  translations: BlogPostTranslation[];
  image: string;
  state: string;
  date_published: number;
  date_created: number;
  date_updated: number;
  owner: string;
  office_id:string;
  meta: any;

  constructor(data: any = {}) {
    this.id = data.id
    this.image = data.image
    this.owner = data.owner
    this.office_id = data.office_id
    this.state = data.state || 'draft'
    this.date_published = data.date_published
    this.date_created = data.date_created
    this.date_updated = data.date_updated
    this.translations = data.translations
  }
}
