/* eslint-disable camelcase */
export class BlogPostTranslation {
  id: string | null
  locale: string
  title: string
  description: string
  short_description: string

  constructor({ id = null, locale = '', title = '', description = '', short_description = '' } = {}) {
    this.id = id
    this.locale = locale
    this.title = title
    this.description = description
    this.short_description = short_description
  }
}
